/* eslint-disable react-hooks/rules-of-hooks */
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import confugureStore from "./app/redux/store/index";
import HttpsRedirect from "react-https-redirect";
import { Auth0Provider } from "@auth0/auth0-react";
const store = confugureStore({});
React.icons = icons;
ReactDOM.render(
  <Provider store={store}>
    <HttpsRedirect>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH_AUTHORITY_URL}
        clientId={process.env.REACT_APP_IDENTITY_CLIENT_ID}
        authorizationParams={{
          redirect_uri: process.env.REACT_APP_IDENTITY_SIGNIN_URL,
          audience: process.env.REACT_APP_IDENTITY_AUDIENCE
        }}
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <App />
      </Auth0Provider>
    </HttpsRedirect>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
