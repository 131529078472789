import axios from "axios";
import { ILS_API_BASE_URL, applicationManagement } from "src/config";

export const getRolesAndSetPermissions = async (token, history) => {
  const isSSOFlag = localStorage.getItem("isSSO") === "true";
  const isMedicareCenterSso =
    localStorage.getItem("IsMedicareCenterSso") === "true";
  const permissionsURI = `${ILS_API_BASE_URL}${applicationManagement}/permissions?isSSO=${isSSOFlag}&IsMedicareCenterSso=${isMedicareCenterSso}`;

  try {
    const { data } = await axios.get(permissionsURI, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    localStorage.setItem("accessToken", token);
    localStorage.setItem("ref_token", data.referenceToken);
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("roper", JSON.stringify(data));
    localStorage.setItem(
      "isTermsAndConditionsRequired",
      data.isTermsAndConditionsRequired
    );
    localStorage.setItem(
      "isShowLeadDispositionNotification",
      data.isShowLeadDispositionNotification
    );

    if (data.isNewUser) {
      history.push("/bu");
    } else {
      history.push("/");
    }
  } catch (error) {
    console.error("Error fetching permissions:", error);
    localStorage.clear();
    localStorage.setItem("errorMsg", "Please contact admin to get more information.");
    window.location.replace("/error");
  }
};
