import { SET_ADCAMPAIGNS, SET_CANMANAGELEADREFUND, SET_CANUPDATELEAD, SET_CANVIEWLEADALL, SET_REALTIMELEADS } from "src/common/constants/actionTypes";
import initialState from "../store/initialState";

export const featuresReducer = (state = initialState.userFeatures, action) => {
  switch (action.type) {
    case SET_CANUPDATELEAD:
      return {
        ...state,
        CanUpdateLead: action.bool,
      }
    case SET_CANMANAGELEADREFUND:
      return {
        ...state,
        CanManageLeadRefund: action.bool,
      }
    case SET_CANVIEWLEADALL:
      return {
        ...state,
        CanViewLeadAll: action.bool,
      }
    case SET_REALTIMELEADS:
      return {
        ...state,
        CanRealTimeLeads: action.bool,
      }
    case SET_ADCAMPAIGNS:
      return {
        ...state,
        CanAdCampaigns: action.bool,
      }
    default:
      return state;
  }
};
