/* eslint-disable array-callback-return */
import React, { useEffect, useContext, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import {useAuth0} from '@auth0/auth0-react';
import { useHistory } from "react-router";
import Textfield from "src/app/auth/textfield";
import AuthAxios from "src/app/helpers/AxiosAuth";
import { Constants } from "src/app/utils/Constants";
import { applicationManagement } from "src/config";
import "../../auth/auth.scss";
import "src/app/components/header/iconHeader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/pro-regular-svg-icons";
import ILSLogo from "src/assets/Images/LeadCENTER_RGB.png";
import authService from "../../redux/services/authService";
import CmLoader from "../../../common/loader/loader";
import MultiSelectDropdown from "src/common/MultiSelect/MultiSelectDropdown";
import Footer from "./Footer";
import Header from "./Header";
import { getAccessToken, getRoper } from "src/app/utils/helperMethods";
import { getRolesAndSetPermissions } from "src/app/utils/authUtils";

const user = getRoper();
let fullName = "";
if (user) {
  fullName = user.firstName + " " + user.lastName;
}

const BuSelectionPage = () => {
  const { logout } = useAuth0();
  const leadSearch = useRef(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedBu, setSelectedBu] = useState(null);
  const [isdiffrentBu, setIsdiffrentBu] = useState(false);
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [fflUserSelected, setFflUserSelected] = useState(false);
  const [disableRequestAccess, setDisableRequestAccess] = useState(true);
  const [disableSubmitRequest, setDisableSubmitRequest] = useState(true);
  const hasPermission = localStorage.getItem("isLoggedIn");
  const [buoptionsList, setBuoptionsList] = useState([]);
  const [isAlreadyRequested, setIsAlreadyRequested] = useState(false);
  const [name, setName] = useState("");
  const [comments, setComments] = useState("");
  const [disableComments, setDisableComments] = useState(true);
  const [npn, setNpn] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (
      !localStorage.getItem("isLoggedIn") ||
      localStorage.getItem("isLoggedIn") === undefined
    ) {
      history.push("/login");
    } else if (
      localStorage.getItem("ref_token") ||
      localStorage.getItem("ref_token").length > 0
    ) {
      history.push("/");
    }
    setIsLoading(false);
    if (hasPermission) {
      setBusinessUnitData();
    }
  }, [hasPermission]);

  useEffect(() => {
    if (selectedBu?.length > 0) {
      setDisableRequestAccess(false);
    } else {
      setDisableRequestAccess(true);
    }
  }, [selectedBu]);

  const setBusinessUnitData = async () => {
    await AuthAxios.get(applicationManagement + "/BusinessUnits")
      .then((json) => {
        const buOptions = json?.data
          ?.filter(
            (option) =>
              -1 ===
                Constants.ExcludeBusinessUnits.indexOf(
                  option.businessUnitIdIdentity
                ) && !option.isInactive
          )
          .map((item) => ({
            ...item,
            display: item.name,
            label: item.name,
            value: item.businessUnitIdIdentity,
          }));
        setBuoptionsList(buOptions);
        setOptions(buOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postRequestAccess = (businessUnitIdIdentities) => {
    const accessToken = getAccessToken();
    let isMedicareCenterSso =
      localStorage.getItem("IsMedicareCenterSso") === "true" ? "true" : "false";
    let url = `/applicationManagement/permissions/newUser`;
    url += `?businessUnitIdIdentities=${businessUnitIdIdentities}&isMedicareCenterSso=${isMedicareCenterSso}`;
    AuthAxios.post(url)
      .then((res) => {
        if (res.status === 200) {
          setFflUserSelected(false);
          setIsAlreadyRequested(true);
          getRolesAndSetPermissions(accessToken, history);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSpecialCharacters = (e) => {
    if (leadSearch?.current?.state?.text === "") {
      setDisableRequestAccess(true);
    }
    if (
      Constants.alphabet.includes(e.key) ||
      Constants.customChars.includes(e.key) ||
      Constants.buttons.includes(e.key) ||
      Constants.numbers.includes(e.key)
    ) {
      // if this condition satisfies then type in
    } else {
      e.preventDefault();
    }
  };

  const handleChangeName = (e) => {
    e.target.value = e.target.value.replace(/[^a-z\d.\- ]/gi, "");
    setName(e.target.value);
    if (e.target.value) {
      setDisableComments(false);
      setDisableSubmitRequest(false);
    } else {
      setComments("");
      setDisableComments(true);
      setDisableSubmitRequest(true);
    }
  };

  const handleChangeComments = (e) => {
    setComments(e.target.value);
  };

  const handleSaveNewRequest = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = { name: name?.trim(), Comments: comments?.trim() };
    let url = `/applicationManagement/BusinessUnits/newrequest`;
    return AuthAxios.post(url, data)
      .then((res) => {
        if (res.status === 200) {
          setIsAlreadyRequested(false);
          setRequestSubmitted(true);
          setNpn(res?.data?.npn);
          setEmail(res?.data?.email);
          setIsdiffrentBu(true);
          postRequestAccess(0);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSaveRequestAccess = (e) => {
    e.preventDefault();
    if (selectedBu) {
      let isFflPresent = isFfl().length > 0;
      if (selectedBu?.length === 1) {
        buCondtionCheck(isFflPresent);
      } else {
        isFflPresent && setFflUserSelected(true);
        const bUs = selectedBu.map((item) => item.businessUnitIdIdentity);
        postRequestAccess(bUs);
      }
    } else {
      setIsdiffrentBu(true);
    }
  };

  const buCondtionCheck = (isFflPresent) => {
    if (isFflPresent) {
      setFflUserSelected(true);
      setDisableRequestAccess(true);
    } else {
      setFflUserSelected(false);
      setDisableRequestAccess(false);
      const bUs = selectedBu.map((item) => item.businessUnitIdIdentity);
      postRequestAccess(bUs);
    }
  };

  const isFfl = () => {
    return selectedBu?.filter((bu) => {
      if (bu.businessUnitIdIdentity === Constants.fflBusinessUnitIdIdentity)
        return bu;
    });
  };

  const LogoutCLick = () => {
    logout({ logoutParams: { returnTo: process.env.REACT_APP_IDENTITY_SIGNOUT_URL } });
  };

  const handleChangeBu = (selection) => {
    setFflUserSelected(false);
    setSelectedBu(selection);
    if (selection.length < 1) {
      setDisableRequestAccess(true);
    } else {
      setDisableRequestAccess(false);
    }
  };

  return (
    <>
      {isAlreadyRequested ? (
        <AlreadyRequestedPage fullName={fullName} LogoutCLick={LogoutCLick} />
      ) : (
        <div className="content-frame register-bu v2">
          {loading ? <CmLoader /> : ""}
          <Header fullName={fullName} />
          {isdiffrentBu ? (
            <div className="container container--size-small">
              <h1 className="text-xl mb-2">{`${
                requestSubmitted ? "Support Request" : "Tell Us More"
              }`}</h1>
              {requestSubmitted ? (
                <div>
                  <p className="color-red">
                    Thank you for contacting Technical Support. Your request has
                    been submitted. You should receive an email with any updates
                    as we research this issue.
                  </p>
                  <label className="label">
                    Here are the details submitted:
                  </label>
                  <p className="mx-0">NPN: {npn}</p>
                  <p className="mx-0 buWordWrap">Email Address: {email}</p>
                  <p className="mx-0 buWordWrap">
                    Missing Business Unit: {name}
                  </p>
                  <p className="mx-0 buWordWrap">
                    Additional Comments: {comments}
                  </p>
                  <div className="form__submit">
                    <button
                      className="btn-v2 mb-4 btn--flex"
                      onClick={() => history.push("/login")}
                    >
                      Return to Login
                    </button>
                  </div>
                </div>
              ) : (
                <form
                  action=""
                  className="form"
                  onSubmit={handleSaveNewRequest}
                >
                  <fieldset className="form__fields">
                    <p className="label custom-text">
                      {`Not sure which insurance marketing organization to select? Please let us know the name of the insurance marketing organizations or agencies you work with.`}
                      <span>{`If you work with more than one agency, enter the name of all the agencies.`}</span>
                    </p>
                    <Textfield
                      id="login-username"
                      className="mb-3"
                      placeholder="Insurance Marketing Organization Name"
                      name="Please provide your primary insurance marketing organization."
                      onChange={handleChangeName}
                      onKeyDown={handleSpecialCharacters}
                    />
                    <label className="label">
                      Would you like to provide additional notes?
                    </label>
                    <Form.Control
                      as="textarea"
                      placeholder=""
                      style={{ height: "100px" }}
                      onChange={handleChangeComments}
                      maxLength="500"
                      disabled={disableComments}
                      value={comments}
                    />
                    <div className="form__submit">
                      <button
                        className="btn-v2 mb-4 btn--flex mr-4 bu-bck-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsdiffrentBu(false);
                          setSelectedBu(null);
                          setComments("");
                          setDisableSubmitRequest(true);
                        }}
                      >
                        Back
                      </button>
                      <button
                        className="btn-v2-disabled mb-4 btn--flex"
                        style={{
                          cursor: disableSubmitRequest
                            ? "not-allowed"
                            : "pointer",
                        }}
                        type="submit"
                        disabled={disableSubmitRequest}
                      >
                        Get Started
                      </button>
                    </div>
                  </fieldset>
                </form>
              )}
            </div>
          ) : (
            <div className="container container--size-small">
              <h1 className="text-xl mb-2 bu-header">Welcome to LeadCENTER.</h1>
              <form
                action=""
                className="form"
                onSubmit={handleSaveRequestAccess}
              >
                <fieldset className="form__fields">
                  <label className="label">
                    To help us better support you, please select your insurance
                    marketing organizations or agencies.
                  </label>
                  <MultiSelectDropdown
                    options={buoptionsList}
                    maxLimit={Constants.buSelectionLimit}
                    onSelect={handleChangeBu}
                    onRemove={handleChangeBu}
                    placeholder={`Please select one or more organizations`}
                    values={selectedBu}
                  />
                  <div className="mt-2" data-gtm="login-forgot-npn">
                    <button
                      className="text-sm link link--force-underline border-0 bg-transparent outline-0 custom-txt"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsdiffrentBu(true);
                      }}
                    >
                      Not sure, or can't find your agency?
                    </button>
                  </div>
                  {fflUserSelected ? (
                    <p className="color-red">
                      Family First Life accounts login through{" "}
                      <a
                        target="_blank"
                        href="https://gateway.uppatop.com/"
                        rel="noreferrer"
                      >
                        www.familyfirstlifelogin.com
                      </a>
                      . Please use this site or contact your administrator for
                      assistance.
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form__submit">
                    <button
                      className="btn-v2-disabled mb-4 btn--flex"
                      style={{
                        cursor: disableRequestAccess
                          ? "not-allowed"
                          : "pointer",
                      }}
                      type="submit"
                      disabled={disableRequestAccess}
                    >
                      Get Started
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          )}
          <Footer />
        </div>
      )}
    </>
  );
};

export default BuSelectionPage;

const AlreadyRequestedPage = ({ fullName, LogoutCLick }) => {
  const history = useHistory();
  return (
    <div className="content-frame register-bu v2">
      <header className="simple-header justify-content-sm-end justify-content-between">
        <h1 className="simple-header__title col-8 col-sm-4 text-center mt-2 mb-3">
          <img
            className="ils-logo img-fluid"
            src={ILSLogo}
            alt="Integrity Lead Store"
          />
          <span className="visually-hidden">Integrity Lead Center</span>
        </h1>
        <div className="col-4 text-right ">
          <div className="row align-items-center justify-content-end">
            <span className="text-uppercase username">{fullName}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button className="btn-v2 btn--flex" onClick={LogoutCLick}>
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
              &nbsp;&nbsp;Logout
            </button>
          </div>
        </div>
      </header>
      <div className="container container--size-small">
        <h1 className="text-lg mb-2">
          We’ve received your request to access LeadCENTER.
        </h1>
        <label className="label mb-4">
          You will receive a confirmation email once your administrator has
          responded to your request.
        </label>
        <div className="form__submit">
          <button
            className="btn-v2 mb-4 btn--flex mt-4"
            onClick={() => history.push("/login")}
          >
            Return to Login
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
