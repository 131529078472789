import initialState from "../store/initialState";

export const voucherReducer = (state = initialState.vouchers, action) => {
  switch (action.type) {
    case "SET_AVAILABLE_CREDIT_BALANCE":
      return Object.assign({}, state, {
        creditBalance: action.credit,
      });
    default:
      return state;
  }
};
