import React, { useEffect } from "react";
import { useAuth0  } from "@auth0/auth0-react";

const LeadCenterSSORedirect = () => {
    const { loginWithRedirect } =  useAuth0();

    useEffect(() => {
        loginWithRedirect();
    }, []);
   
    return (
        <div className="content-frame v2">
            Redirect
        </div>
    );
};

export default LeadCenterSSORedirect;