import { applicationErrorReducer } from "./applicationReducer";
import { sidebarReducer } from "./sidebarReducer";
import { manageLeadsReducer } from "./manageLeadsReducer";
import { orderLeadsReducer } from "./orderLeadsReducer";
import { loginReducer } from "./reducer";
import { combineReducers } from 'redux';
import { shoppingCartReducer } from './shoppingCartReducer';
import { agentReducer } from './AgentReducer';
import { couponReducer } from './couponReducer';
import { eGiftsReducer } from './eGiftsReducer';
import { RtlCampaignsReducer } from './RealTimeLeadsReducer';
import { voucherReducer } from './voucherReducer';
import { transactionsReducer } from './transactionHistoryReducer';
import { featuresReducer } from './featuresReducer';
import { faqReducer } from './faqReducer';
import { userProfilesReducer } from "./userProfilesReducer";


export default combineReducers({
    application: combineReducers({
        errors: applicationErrorReducer
    }),
    loginReducer,
    manageLeadsReducer,
    orderLeadsReducer,
    sidebarReducer,
    shoppingCartReducer,
    agentReducer,
    couponReducer,
    eGiftsReducer,
    RtlCampaignsReducer,
    voucherReducer,
    transactionsReducer,
    featuresReducer,
    faqReducer,
    userProfilesReducer
});
