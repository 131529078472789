import {
  ACTION_SET_CREATIVE_NAMES,
  ACTION_SET_CUSTOM_AD_CAMPAIGN_TYPES,
  GET_REALTIMELEADS_CAMPAIGNS_LIST_FAILURE,
  GET_REALTIMELEADS_CAMPAIGNS_LIST_SUCCESS,
} from "src/common/constants/actionTypes";
import initialState from "../store/initialState";

export const RtlCampaignsReducer = (
  state = initialState.campaignsData,
  action
) => {
  switch (action.type) {
    case GET_REALTIMELEADS_CAMPAIGNS_LIST_SUCCESS:
      return {
        ...state,
        campaignsData: action.list,
      };

    case GET_REALTIMELEADS_CAMPAIGNS_LIST_FAILURE:
      return {
        ...state,
        campaignsData: {},
      };

    case "ACTION_SECLECTED_RECORDS":
      return {
        ...state,
        records: action.records,
      };

    case "ACTION_SELECTED_CAMPAIGNIDS":
      return {
        ...state,
        campaignIdIdentities: action.campaignIdIdentities,
      };

    case "STATE_DATA":
      return {
        ...state,
        states: action.states,
      };

    case "COUNTIES_DATA":
      return {
        ...state,
        counties: action.counties,
      };

    case "CAMPAIGN_TYPES":
      return {
        ...state,
        campaignTypes: action.campaignTypes,
      };

    case "PURCHASE_TYPES":
      return {
        ...state,
        purchaseTypes: action.purchaseTypes,
      };

    case "PRODUCTS":
      return {
        ...state,
        products: action.products,
      };

    case "PRODUCT_CATEGORIES":
      return {
        ...state,
        productCategories: action.productCategories,
      };

    case "RTL_LEAD_TYPES":
      return {
        ...state,
        rtlLeadTypes: action.rtlLeadTypes,
      };
    case ACTION_SET_CUSTOM_AD_CAMPAIGN_TYPES:
      return {
        ...state,
        customAdCampaignTypes: action.campaignTypes,
      };
    case ACTION_SET_CREATIVE_NAMES:
      return {
        ...state,
        customAdCreativeNames: action.creativeNames,
      };
    case "SET_BPL_CAMPAIGN_STATUSES":
      return {
        ...state,
        bplCampaignStatuses: action.statuses,
      };
    case "SET_CAMPAIGNS_STATUSES":
      return {
        ...state,
        campaigns_statuses: action.statuses
      }
    default:
      return {
        ...state,
      };
  }
};
